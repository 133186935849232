import React from "react";
import {
  Redirect,
  Route,
} from "react-router-dom";

import DashboardLayout from 'layouts/dashboard';

const ProtectedRoute = ({ component: Component, role, ...rest }) => {
  let auth = localStorage.getItem("currentUser");
  if (auth) {
    auth = JSON.parse(auth);
  }

  return (
    <Route 
      {...rest}

      render={(props) => {
        // Check if already login
        if (!auth) {
          if (role === "superAdmin") {
            return (
              <Redirect to="/login" />
            )
          }

          return (
            <Redirect to="/login" />
          );
        }

        // Check if access permission is correct
        if (auth.role !== role) {
          if (auth.role === "superAdmin")
            return (
              <Redirect to="/admin" />
            );
          else
            return (
              <Redirect to="/login" />
            );
        }

        if (auth) return (
            <DashboardLayout role={role}>
              <Component {...props} />
            </DashboardLayout>
        )
      }}
    />
  )
}

export default ProtectedRoute;