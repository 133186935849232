export const NavbarHeight = 80;
export const SidebarWidth = 265;

// Order Statuses
export const ALL_ORDERS = "ALL";
export const PENDING_ORDERS = "Pending";
export const SHIPPED_ORDERS = "Order Have Been Send to Courier";
export const Delivered = "Delivered";
export const Received = "Received";

// Product Detail Tabs
export const PRODUCT_DETAIL = "Detail";
export const PRODUCT_REVIEWS = "Reviews";
