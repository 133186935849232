import React, { useMemo, useState } from "react";
import { Modal, TableContainer, Box } from "@mui/material";
import {
  DarkColor,
  LightColor,
  WindowBackgroundColor,
} from "../../Assets/Colors";
//         Importing Variables
import {
  SidebarWidth,
  PRODUCT_DETAIL,
  PRODUCT_REVIEWS,
} from "../../Assets/Variables";
//         Importing Components
import { useWindowDimensions } from "../../Assets/GetDimentions";
import { MyImage } from "../../Assets/Image";
import { Rating } from "../../Assets/Rating";
import { Detail } from "../../Assets/ProductDetail/detail";

import TableList from "components/tableList";

export default function ModalVariant({ open, setOpen, product, isCrawler }) {
  const [tab, setTab] = React.useState(PRODUCT_DETAIL);
  // Stylesheet
  const { width } = useWindowDimensions();
  const containerWidth = width - (SidebarWidth + 120);
  const productImageContainerWidth = containerWidth / 2;
  const dataContainerWidth = containerWidth / 2;
  const [sizes, setSizes] = useState(product?.details?.colors[0]);
  const [colors, setColor] = useState(product?.details?.sizes[0]);
  const [focus, setFocus] = useState(product?.details?.images[0]);

  const handlesize = (size) => {
    setSizes(size);
  };
  const handlecolor = (color) => {
    setColor(color);
  };
  const Style = {
    container: {
      width: `${containerWidth}px`,
      display: "flex",
      flexDirection: "row",
    },
    productImagesContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: `${productImageContainerWidth}px`,
      marginRight: "10px",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: `${dataContainerWidth}px`,
      marginLeft: "10px",
      padding: "0 15px",
    },
    name: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "40px",
      color: DarkColor,
    },
    description: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "22px",
      lineHeight: "34px",
      color: DarkColor,
      marginTop: "10px",
    },
    colorContainer: {
      width: "100%",
      borderRadius: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",

      boxSizing: "border-box",
    },
    color: {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
    },
    price: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: "10px",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "40px",
      color: DarkColor,
    },
    tabContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "50px 0 50px 0",
    },
    selectedTab: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "40px",
      color: DarkColor,
      cursor: "pointer",
      width: "auto",
      borderBottom: `1px solid ${DarkColor}`,
      textTransform: "capitalize",
      userSelect: "none",
    },
    unSelectedTab: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "40px",
      color: LightColor,
      cursor: "pointer",
      width: "auto",
      borderBottom: `1px solid ${WindowBackgroundColor}`,
      textTransform: "capitalize",
      userSelect: "none",
    },
  };

  const columns = useMemo(() => {
    return [
      {
        name: "Product",
        selector: (row) => (
          <img style={{ width: "8vh", padding: "8px" }} src={row.image} />
        ),
      },

      {
        name: "Color",
        selector: (row) => row?.colors[0],
      },
      {
        name: "Size",
        selector: (row) => row?.sizes[0],
      },

      {
        name: "Price",
        selector: (row) => row.price,
      },
      // {
      //   name: "Discount",
      //   selector: (row) => row.productDiscount,
      // },
      {
        name: "Total",
        selector: (row) => row.price,
      },
    ];
  }, [product]);

  const sources = useMemo(() => {
    if (!product) return [];
    if (!product.details) return [];

    return product.details?.images.map((valueSku) => {
      let image = "";
      if (product.details?.images.length > 0) {
        if (isCrawler) image = valueSku;
        else image = product.details?.images[0];
      }

      return {
        image,
        ...product.details,
      };
    });
  }, [product]);

  const sources1 = useMemo(() => {
    if (!product) return [];
    if (!product.images) return [];

    return product?.images.map((valueSku) => {
      let image = "";
      if (product?.images.length > 0) {
        if (isCrawler) image = valueSku.id;
        else image = product?.images[0]?.id;
      }

      return {
        image,
        ...product,
      };
    });
  }, [product]);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      style={{
        width: "85%",
        height: "75%",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          minWidth: 1000,
          maxHeight: 650,
          overflow: "auto",
          backgroundColor: "white",
        }}
      >
        {/* <TableContainer
          sx={{ minWidth: 800, maxHeight: 400, overflow: "auto" }}
        >
          <TableList
            columns={columns}
            data={!product?.details ? sources1 : sources}
            expand={false}
            pagination={false}
          />
        </TableContainer> */}
        <div style={{ margin: "50px" }}>
          <div style={{ ...Style.container }}>
            <div style={{ ...Style.productImagesContainer }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "10px",
                  marginRight: "24px",
                }}
              >
                {product?.details?.images?.map((e) => (
                  <div onClick={() => setFocus(e)}>
                    <MyImage
                      dimension={100}
                      borderRadius="0px"
                      src={e}
                      alt="product-pic"
                    />
                  </div>
                ))}
              </div>
              <MyImage
                dimension={
                  productImageContainerWidth -
                  (productImageContainerWidth / 5 + 5)
                }
                borderRadius="0px"
                src={focus ? focus : product?.details?.images[0]}
                alt="product-pic"
              />
            </div>
            <div style={{ ...Style.dataContainer }}>
              <div style={{ ...Style.name }}>
                {product?.details?.productTitle}
              </div>
              <div style={{ ...Style.description }}>
                {product?.details?.description.substring(0, 110)}
              </div>
              <label style={{ fontSize: "18px", marginTop: "20px" }} htmlFor="">
                Color :
              </label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                  gridGap: "10px",
                  marginTop: "10px",
                }}
              >
                
                  {product?.details?.colors?.map((li) => {
                    return (
                      <div
                        onClick={() => handlesize(li.text)}
                        style={
                          sizes === li.text
                            ? {
                                ...Style.colorContainer,
                                minWidth: "70px",
                                backgroundColor: "#ff6969",
                              }
                            : {
                                ...Style.colorContainer,
                                minWidth: "70px",
                                backgroundColor: "gray",
                              }
                        }
                      >
                        <text style={{ padding: "5px" }}>
                          {li?.substring(0, 5)}
                        </text>
                      </div>
                    );
                  })}
                {/* <Rating count={5} value={0} /> */}
              </div>
              <div style={{ ...Style.price }}>{product?.details?.price}</div>
              <div style={{ position: "relative" }}>
                <label style={{ fontSize: "18px" }} htmlFor="">
                  Size :
                </label>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(8, 1fr)",
                    gridGap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {product?.details?.sizes?.map((li) => {
                    return (
                      <div
                        onClick={() => handlecolor(li.text)}
                        style={
                          colors === li.text
                            ? {
                                ...Style.colorContainer,
                                minWidth: "35px",
                                backgroundColor: "#ff6969",
                              }
                            : {
                                ...Style.colorContainer,
                                minWidth: "35px",
                                backgroundColor: "gray",
                              }
                        }
                      >
                        <text style={{ padding: "5px" }}>{li}</text>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div style={{ ...Style.tabContainer }}>
            <div
              style={
                tab === PRODUCT_DETAIL
                  ? { ...Style.selectedTab }
                  : { ...Style.unSelectedTab }
              }
              onClick={() => {
                setTab(PRODUCT_DETAIL);
              }}
            >
              {PRODUCT_DETAIL}
            </div>
          </div>
          {tab === PRODUCT_DETAIL ? (
            <Detail
              image={
                product?.details?.images[0] ? product?.details?.images[0] : null
              }
              detail={product?.details?.details}
            />
          ) : (
            ""
          )}
        </div>
      </Box>
    </Modal>
  );
}
