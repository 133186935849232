import axios from "axios";
import { API_URL } from "configures/setting";

export const getToken = () => localStorage.getItem("accessToken");
export const getOrg = () => localStorage.getItem("orgid");

const customAxios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

customAxios.interceptors.request.use(
  (request) => {
    request.headers.Authorization = `${getToken()}`;
    request.headers.orgid = `${getOrg()}`;
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default customAxios;
