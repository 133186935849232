import { Icon } from '@iconify/react';
import shoppingCartFill from '@iconify/icons-eva/shopping-cart-fill';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import bookFill from '@iconify/icons-eva/book-open-fill';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const userSidebar = [
  {
    title: 'product',
    path: '/product',
    icon: getIcon(shoppingCartFill)
  },
];

export default userSidebar;
