import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
// services
import { getUsers } from "services/adminUser.service";

export default function User() {
  const { isSuccess, data } = useQuery(["users"], getUsers);
 
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setUsers(data);
    }
  }, [isSuccess, data]);

  const columnUsers = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "First Name",
        selector: row => row.firstName,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: row => row.lastName,
        sortable: true
      },
      {
        name: "Email",
        selector: row => row.email,
      },
      {
        name: "National Id",
        selector: row => row.nationalId,
      },
      {
        name: "Contact No",
        selector: row => row.contactNo,
      }
    ]
  }, []);

  const sourceUsers = useMemo(() => {
    return users;
  }, [users]);

  return (
    <Page title="User | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnUsers}
                data={sourceUsers}
              />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
