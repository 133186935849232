export const getLocalStorageUser = () => {
  let user = "";
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    user = currentUser;
  }

  return user;
}

export const sortLogDate = (a, b) => {
  if ( a.logDate < b.logDate ){
    return 1;
  }
  if ( a.logDate > b.logDate ){
    return -1;
  }

  return 0;
}