import React from "react";
//          Importing Colors
import { DarkColor } from "../../Assets/Colors";
import parse from "html-react-parser";

export const Detail = ({ image, detail }) => {
  const Style = {
    container: {
      backgroundImage: `linear-gradient( 270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 47.81%, #FFFFFF 100%), url(${
        image ? image : "https://picsum.photos/1001/1001"
      })`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100%",
      padding: "35px 30px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    header: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "35px",
      color: DarkColor,
    },
    value: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "35px",
      color: DarkColor,
    },
  };
  return <div style={{ ...Style.container }}>{parse(detail)}</div>;
};
