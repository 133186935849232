import { 
	UPDATE_SELECTED_APP,
	UPDATE_SELECTED_CORP,
} from 'store/constants/global';

const initial_state = {
	selectedApp: "",
	selectedCorp: "",
};

export default function (state = initial_state, action) {
	switch (action.type) {
		case UPDATE_SELECTED_APP:
			return {
				...state,
				selectedApp: action.value
			};
		case UPDATE_SELECTED_CORP:
			return {
				...state,
				selectedCorp: action.value
			};
		default:
			return state;
	}
}
