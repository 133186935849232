import { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { useSelector, useDispatch } from 'react-redux';
// material
import { styled } from '@mui/material/styles';
// service & redux
import { updateSelectedApp, updateSelectedCorp } from 'store/actions/global';
import { getAvailableApps, getCorporateApps } from "services/app.service";
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({role, children}) {
  const dispatch = useDispatch();
  const selectedApp = useSelector((state) => state._global?.selectedApp);
  const selectedCorp = useSelector((state) => state._global?.selectedCorp);

  const [open, setOpen] = useState(false);
  const { isSuccess, data: resApp } = useQuery(["apps"], getAvailableApps);
  const { isSuccess: isSuccessCorp, data: resCorp } = useQuery(["admin-corps"], getCorporateApps);

  const [apps, setApps] = useState([]);
  const [corps, setCorps] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setApps(resApp);
      if (selectedApp === "" && resApp) {
        if (resApp.length > 0) {
          dispatch(updateSelectedApp(resApp[0].uId));
        }
      }
    }
  }, [isSuccess, resApp]);

  useEffect(() => {
    if (isSuccess) {
      setCorps(resCorp);
      if (selectedCorp === "" && resCorp) {
        if (resCorp.length > 0) {
          dispatch(updateSelectedCorp(resCorp[0].uId));
        }
      }
    }
  }, [isSuccessCorp, resCorp]);

  const onChangeApp = (value) => {
    dispatch(updateSelectedApp(value));
  }

  const onChangeCorp = (value) => {
    dispatch(updateSelectedCorp(value));
  }

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar 
        apps={apps} 
        selectedApp={selectedApp}
        corps={corps} 
        selectedCorp={selectedCorp}
        role={role} 
        isOpenSidebar={open} 
        onCloseSidebar={() => setOpen(false)} 
        onChangeApp={onChangeApp}
        onChangeCorp={onChangeCorp}
      />
      <MainStyle>
        {children}
      </MainStyle>
    </RootStyle>
  );
}
