import React, { useState, useMemo, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import dayjs from "dayjs";
import { alertSuccess, alertError } from "../../services/alert.service";
// material
import {
  Button,
  Grid,
  Card,
  Stack,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "components/Page";
import Scrollbar from "components/Scrollbar";
import TableList from "components/tableList";

import ModalVariant from "./modalVariant";
import ModalProductVariant from "./modelProduct";
// service
import { getCrawlers, uploadJson } from "services/crawler.service";
import {
  getAppProducts,
  syncProducts,
  getSync,
} from "services/product.service";

const StyledLink = styled.span`
  color: #2196f3;
  cursor: pointer;
`;

export default function Product() {
  const selectedApp = useSelector((state) => state._global?.selectedApp);
  const selectedCorp = useSelector((state) => state._global?.selectedCorp);

  const [Crawler, setCrawler] = useState();
  const [syncDate, setSyncDate] = useState(null);

  const [reRun, setReRun] = useState(true);
  const [appProducts, setAppProducts] = useState([]);
  const [openVariant, setOpenVariant] = useState(false);
  const [openProductVariant, setOpenProductVariant] = useState(false);
  const [CrawlerDetail, setCrawlerDetail] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const [isCrawlerDetail, setIsCrawlerDetail] = useState(false);

  useEffect(() => {
    if (selectedApp !== "" && selectedCorp !== "") {
      console.log(selectedApp, selectedCorp);
      getAppProducts({
        appId: selectedApp,
        organizationId: selectedCorp,
      }).then((res) => {
        setAppProducts(res.values);
        setSyncDate(res.syncDate);
      });
    }
  }, [selectedApp, selectedCorp, reRun]);

  useEffect(() => {
    if (selectedApp !== "" && selectedCorp !== "") {
      getCrawlers({
        appId: selectedApp,
        organizationId: selectedCorp,
      }).then((res) => {
        console.log(res);
        setCrawler(res.data);
      });
    }
  }, [selectedApp, selectedCorp]);

  const columnProducts = (isCrawler) => {
    const columns = [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "50px",
      },
      {
        name: "Name",
        selector: (row) => row?.details.productTitle,
        width: "250px",
      },

      {
        name: "Category",
        selector: (row) => row?.details.category,
      },
      {
        name: "SubCategory",
        selector: (row) => row?.details.subCategory,
      },
      {
        name: "Price",
        selector: (row) => row?.details.price,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <StyledLink
              onClick={() => {
                setOpenVariant(true);
                setIsCrawlerDetail(isCrawler);
                setCrawlerDetail(row);
              }}
            >
              View Detail
            </StyledLink>
          );
        },
      },
    ];

    return columns;
  };
  const columnSync = (isCrawler) => {
    const columns = [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "50px",
      },
      {
        name: "Name",
        selector: (row) => row?.name,
        width: "250px",
      },

      {
        name: "Category",
        selector: (row) => row?.product_category,
      },
      {
        name: "SubCategory",
        selector: (row) => row?.product_sub_category,
      },
      {
        name: "Price",
        selector: (row) => `$${row?.productVariant[0]?.Price}`,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <StyledLink
              onClick={() => {
                setOpenProductVariant(true);
                setIsCrawlerDetail(isCrawler);
                setProductDetail(row);
              }}
            >
              View Detail
            </StyledLink>
          );
        },
      },
    ];

    return columns;
  };
  const logSync = () => {
    const columns = [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "50px",
      },
      {
        name: "Name",
        selector: (row) => row?.name,
        width: "250px",
      },

      {
        name: "Category",
        selector: (row) => row?.product_category,
      },
      {
        name: "SubCategory",
        selector: (row) => row?.product_sub_category,
      },
      {
        name: "Price",
        selector: (row) => row?.price,
      },
      {
        name: "Date",
        selector: (row) =>
          row ? dayjs(row?.date).format("DD-MM-YYYY HH:mm:ss") : "-",
        width: "250px",
      },
    ];

    return columns;
  };

  const sourceCrawlerProducts = useMemo(() => {
    return Crawler;
  }, [Crawler]);

  const sourceProducts = useMemo(() => {
    if (appProducts) return appProducts;
    return [];
  }, [appProducts]);

  const onSync = () => {
    const values = {
      appId: selectedApp,
      organizationId: selectedCorp,
    };

    syncProducts(values)
      .then(() => {
        getSync().then(() => {
          setReRun(!reRun);
          alertSuccess("Success");
        });
      })
      .catch((err) => {
        alertError(err.response?.data?.message ?? "");
      });

    // mutateSyncProduct.mutate(values, {
    //   onSuccess: () => {
    //     setReRun(!reRun);
    //   },
    // });
  };

  console.log(openVariant, openProductVariant);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("appId", selectedApp);
    formData.append("organizationId", selectedCorp);
    formData.append("file", file);

    uploadJson(formData).then((res) => {
      console.log(res);
    });
  };

  return (
    <Page title="Product | Minimal-UI">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4" gutterBottom>
              Crawler Products
            </Typography>

            <Button
              size="medium"
              type="button"
              variant="contained"
              component="label"
            >
              Upload
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <TableList
                  columns={columnProducts(true)}
                  data={sourceCrawlerProducts}
                />
              </TableContainer>
            </Scrollbar>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4" gutterBottom>
              Products
            </Typography>

            <span>{`Latest Sync : ${
              syncDate ? dayjs(syncDate).format("DD-MM-YYYY HH:mm:ss") : " - "
            }`}</span>
            <Button
              size="medium"
              type="button"
              variant="contained"
              onClick={() => onSync()}
            >
              Sync Now
            </Button>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <TableList
                  columns={columnSync(true)}
                  syncColumns={logSync()}
                  data={sourceProducts}
                  expand={true}
                />
              </TableContainer>
            </Scrollbar>
          </Card>
        </Grid>
      </Grid>

      <ModalVariant
        open={openVariant}
        setOpen={setOpenVariant}
        product={CrawlerDetail}
        isCrawler={isCrawlerDetail}
      />

      <ModalProductVariant
        open={openProductVariant}
        setOpen={setOpenProductVariant}
        data={productDetail}
        isCrawler={isCrawlerDetail}
      />
    </Page>
  );
}
