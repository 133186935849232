// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// react-query-config
import queryConfig from "configures/queryConfig";

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import store from './store';

const queryClient = new QueryClient(queryConfig);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
    </HelmetProvider>
    {process.env.NODE_ENV !== "production" && <ReactQueryDevtools position="bottom-right" />}
    </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
