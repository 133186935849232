import { 
  applyMiddleware, 
  combineReducers, 
  createStore, 
  compose 
} from "redux";
import thunk from 'redux-thunk';

import globalState from "./reducers/global";

const rootReducer = combineReducers({
  _global: globalState,        
});

const middleware = [thunk];
const initialState = {};

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);

const store = createStore(
  rootReducer,
  initialState,
  enhancer,
);

export default store