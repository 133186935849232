import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { sortLogDate } from "utils/common";

const TableWrapper = styled.div`
  .rdt_TableHead .rdt_TableCol {
    font-weight: bold;
  }

  .rdt_ExpanderRow .rdt_Table {
    padding: 10px 0px;

    .rdt_TableHead {
      display: none;
    }
    .rdt_TableBody .rdt_TableRow div:first-child button {
      display: none;
    }
    .rdt_TableRow > div {
      background-color: #f3f3f3;
    }
  }
`;

const TableList = ({
  columns,
  data,
  expand,
  pagination,
  props,
  syncColumns,
}) => {
  const ExpandedComponent = (row) => {
    return (
      <DataTable
        columns={syncColumns}
        data={row.sync ? row.sync.sort(sortLogDate) : []}
        expandableRows={true}
      />
    );
  };

  return (
    <TableWrapper {...props}>
      <DataTable
        columns={columns}
        data={data}
        expandableRows={expand}
        expandableRowsComponent={(row) => ExpandedComponent(row.data)}
        pagination={pagination}
      />
    </TableWrapper>
  );
};

TableList.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  expand: PropTypes.bool,
  pagination: PropTypes.bool,
};

TableList.defaultProps = {
  columns: [],
  data: [],
  expand: false,
  pagination: true,
};

export default TableList;
