import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import Label from 'components/Label';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';
// services
import { getAvailableApps, deleteApp } from "services/app.service";

export default function App() {
  const history = useHistory();
  const { isSuccess, data: resApp } = useQuery(["userapps"], getAvailableApps);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
 
  const [apps, setApps] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setApps(resApp);
    }
  }, [isSuccess, resApp]);

  const handleDelete = useCallback(async () => {
    const res = await deleteApp({deleteId});
    if (res) {
      setApps(apps.filter(x => x._id !== deleteId));
    }

    setShow(false);
  }, [deleteId, apps]);

  const columnApps = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Code",
        selector: row => row.code,
        sortable: true
      },
      {
        name: "Status",
        selector: row => row.status,
        sortable: true,
        cell: row => {
          return (
            <Label
              variant="ghost"
              color={row.status ? "success" : "warning"}
            >
              {row.status ? "Active" : "Not Active"}
            </Label>
          )
        }
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/admin/app/edit/${row._id}`)}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceApps = useMemo(() => {
    return apps;
  }, [apps]);

  return (
    <Page title="App | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            App
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/app/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New App
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnApps}
                data={sourceApps}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
