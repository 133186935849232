import axiosReq from "utils/axiosConfig";
import { alertError } from "./alert.service";

export const getUsers = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/users`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getUser = async ({ queryKey }) => {
  const { userId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/users/${userId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}