import axiosReq from "utils/axiosConfig";
import { alertSuccess, alertError } from "./alert.service";

export const getApps = async payload => {
  try {
    const res = await axiosReq.get(`/api/common/product/apps`, payload);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getAvailableApps = async payload => {
  try {
    const res = await axiosReq.get(`/api/common/apps/available`, payload);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getApp = async ({ queryKey }) => {
  const { appId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/common/apps/${appId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getCorporateApps = async payload => {
  try {
    const res = await axiosReq.get(`/api/common/apps/corporate`, payload);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createApp = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/apps/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateApp = async ({values, appId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/apps/${appId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteApp = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/apps/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}