import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from 'pages/Login';
// admin pages
import AdminDashboard from 'pages/admin/dashboard';
import AdminUser from 'pages/admin/user';
import AdminApp from 'pages/admin/app';
import AdminCreateApp from 'pages/admin/app/Create';
import AdminEditApp from 'pages/admin/app/Edit';
// user pages
import UserDashboard from 'pages/user/dashboard';
// shared pages
import Product from 'pages/product';

import ProtectedRoute from "./protected.route";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />

        {/* admin */}
        <ProtectedRoute exact path="/admin" role="superAdmin" component={AdminDashboard} />
        <ProtectedRoute exact path="/admin/user" role="superAdmin" component={AdminUser} />
        <ProtectedRoute exact path="/admin/app" role="superAdmin" component={AdminApp} />
        <ProtectedRoute exact path="/admin/app/create" role="superAdmin" component={AdminCreateApp} />
        <ProtectedRoute exact path="/admin/app/edit/:id" role="superAdmin" component={AdminEditApp} />
        <ProtectedRoute exact path="/admin/product" role="superAdmin" component={Product} />
        {/* user */}
        <ProtectedRoute exact path="/" role="admin" component={UserDashboard} />
        <ProtectedRoute exact path="/product" role="admin" component={Product} />
      </Switch>
    </Router>
  );
}

export default AppRouter;