import axiosReq from "utils/axiosConfig";
import { alertSuccess, alertError } from "./alert.service";

export const getCrawlers = async payload => {
  try {
    const res = await axiosReq.get("/api/common/crawlers/get", {
      params: payload
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getCrawlerProducts = async ({ queryKey }) => {
  const { appId, organizationId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/common/crawlers/products`, {
      params: {
        appId: appId,
        organizationId: organizationId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const uploadJson = async (values) => {
  try {
    const res = await axiosReq.post(`/api/common/crawlers/json/`, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}
