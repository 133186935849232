import {
  UPDATE_SELECTED_APP,
  UPDATE_SELECTED_CORP,
} from "store/constants/global";

export const updateSelectedApp = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_APP,
    value
  });
};

export const updateSelectedCorp = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_CORP,
    value
  });
};