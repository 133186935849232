import { Icon } from '@iconify/react';
import shoppingCartFill from '@iconify/icons-eva/shopping-cart-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import gridFill from '@iconify/icons-eva/grid-fill';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import bookFill from '@iconify/icons-eva/book-open-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const adminSidebar = [
  // {
  //   title: 'user',
  //   path: '/admin/user',
  //   icon: getIcon(peopleFill)
  // },
  {
    title: 'app',
    path: '/admin/app',
    icon: getIcon(gridFill)
  },
  {
    title: 'product',
    path: '/admin/product',
    icon: getIcon(shoppingCartFill)
  },
  // {
  //   title: 'category',
  //   path: '/admin/category',
  //   icon: getIcon(keypadFill)
  // },
  // {
  //   title: 'brand',
  //   path: '/admin/brand',
  //   icon: getIcon(bookFill)
  // }
];

export default adminSidebar;
