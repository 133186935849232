import React, { useMemo, useState } from "react";
import { Modal, TableContainer, Box } from "@mui/material";
import {
  DarkColor,
  LightColor,
  WindowBackgroundColor,
} from "../../Assets/Colors";
//         Importing Variables
import {
  SidebarWidth,
  PRODUCT_DETAIL,
  PRODUCT_REVIEWS,
} from "../../Assets/Variables";
//         Importing Components
import { useWindowDimensions } from "../../Assets/GetDimentions";
import { MyImage } from "../../Assets/Image";
import { Rating } from "../../Assets/Rating";
import { Detail } from "../../Assets/ProductDetail/detail";

import TableList from "components/tableList";

export default function ModalProductVariant({
  open,
  setOpen,
  data,
  isCrawler,
}) {
  const [tab, setTab] = React.useState(PRODUCT_DETAIL);
  // Stylesheet
  const { width } = useWindowDimensions();
  const containerWidth = width - (SidebarWidth + 120);
  const productImageContainerWidth = containerWidth / 2;
  const dataContainerWidth = containerWidth / 2;
  const [sizes, setSizes] = useState(data?.Variant1?.type[0]?.text);
  const [colors, setColor] = useState(data?.Variant2?.type[0]?.text);

  const [focus, setFocus] = useState(data?.product_image[0]?.img);

  const handlesize = (size) => {
    setSizes(size);
  };
  const handlecolor = (color) => {
    setColor(color);
  };
  const Style = {
    container: {
      width: `${containerWidth}px`,
      display: "flex",
      flexDirection: "row",
    },
    productImagesContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: `${productImageContainerWidth}px`,
      marginRight: "10px",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: `${dataContainerWidth}px`,
      marginLeft: "10px",
      padding: "0 15px",
    },
    name: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "40px",
      color: DarkColor,
    },
    description: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "22px",
      lineHeight: "34px",
      color: DarkColor,
      marginTop: "10px",
    },
    colorContainer: {
      width: "100%",
      height: "31px",
      borderRadius: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "8px",
      cursor: "pointer",

      boxSizing: "border-box",
    },
    color: {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
    },
    price: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: "10px",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "40px",
      color: DarkColor,
    },
    tabContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "50px 0 50px 0",
    },
    selectedTab: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "40px",
      color: DarkColor,
      cursor: "pointer",
      width: "auto",
      borderBottom: `1px solid ${DarkColor}`,
      textTransform: "capitalize",
      userSelect: "none",
    },
    unSelectedTab: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "40px",
      color: LightColor,
      cursor: "pointer",
      width: "auto",
      borderBottom: `1px solid ${WindowBackgroundColor}`,
      textTransform: "capitalize",
      userSelect: "none",
    },
  };

  let price = data?.productVariant?.filter(
    (e) => e.V1Tag === sizes && e.V2Tag === colors
  );
  let weight = data?.productVariant?.filter(
    (e) => e.V1Tag === sizes && e.V2Tag === colors
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      style={{
        width: "85%",
        height: "75%",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          minWidth: 1000,
          maxHeight: 650,
          overflow: "auto",
          backgroundColor: "white",
        }}
      >
        <div style={{ margin: "50px" }}>
          <div style={{ ...Style.container }}>
            <div style={{ ...Style.productImagesContainer }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "10px",
                  marginRight: "24px",
                }}
              >
                {data?.product_image?.map((e) => (
                  <div onClick={() => setFocus(e.img)}>
                    <MyImage
                      dimension={100}
                      borderRadius="0px"
                      src={e.img}
                      alt="product-pic"
                    />
                  </div>
                ))}
              </div>
              <MyImage
                dimension={
                  productImageContainerWidth -
                  (productImageContainerWidth / 5 + 5)
                }
                borderRadius="0px"
                src={focus ? focus : data?.product_image[0]?.img}
                alt="product-pic"
              />
            </div>
            <div style={{ ...Style.dataContainer }}>
              <div style={{ ...Style.name }}>{data?.name}</div>
              <div style={{ ...Style.description }}>
                {data?.description.substring(0, 110)}.
              </div>
              <label style={{ fontSize: "18px", marginTop: "20px" }} htmlFor="">
                {data?.Variant1?.name} :
              </label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                  gridGap: "10px",
                  marginTop: "10px",
                }}
              >
                {data?.Variant1?.type?.slice(0, 5)?.map((li) => {
                  return (
                    <div
                      onClick={() => handlesize(li.text)}
                      style={{
                        ...Style.colorContainer,
                        backgroundColor: "#ff6969",
                      }}
                    >
                      <text style={{ padding: "5px" }}>
                        {li.text.substring(0, 5)}
                      </text>
                    </div>
                  );
                })}
              </div>
              <div style={{ ...Style.price }}>
                ${data?.productVariant[0]?.Price}
              </div>
              <div style={{ position: "relative" }}>
                <label style={{ fontSize: "18px" }} htmlFor="">
                  {data?.Variant2?.name} :
                </label>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(8, 1fr)",
                    gridGap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {data?.Variant2?.type?.slice(0, 5)?.map((li) => {
                    return (
                      <div
                        onClick={() => handlecolor(li.text)}
                        style={{
                          ...Style.colorContainer,
                          backgroundColor: "#ff6969",
                        }}
                      >
                        <text style={{ padding: "5px" }}>{li.text}</text>
                      </div>
                    );
                  })}
                </div>
                {/* <label
                  style={{
                    fontSize: "18px",
                    position: "relative",
                    top: "20px",
                  }}
                  htmlFor=""
                >
                  Weight :
                </label>
                <div
                  style={{
                    position: "relative",
                    top: "30px",
                    fontSize: "22px",
                  }}
                > */}
                {/* {!weight[0]?.weight
                    ? data?.productVariant[0]?.weight
                    : weight[0]?.weight} */}
                {/* Kg */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div style={{ ...Style.tabContainer }}>
            <div
              style={
                tab === PRODUCT_DETAIL
                  ? { ...Style.selectedTab }
                  : { ...Style.unSelectedTab }
              }
              onClick={() => {
                setTab(PRODUCT_DETAIL);
              }}
            >
              {PRODUCT_DETAIL}
            </div>
          </div>
          {tab === PRODUCT_DETAIL ? (
            <Detail
              image={data?.product_image ? data?.product_image[0]?.img : null}
              detail={data?.product_detail}
            />
          ) : (
            ""
          )}
        </div>
      </Box>
    </Modal>
  );
}
