import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import styledElement from "styled-components";
import { Box, Drawer, Select, MenuItem } from "@mui/material";
// components
import Logo from "components/Logo";
import Scrollbar from "components/Scrollbar";
import NavSection from "components/NavSection";
import { MHidden } from "components/@material-extend";
//
import adminSidebar from "./AdminSidebar";
import userSidebar from "./UserSidebar";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AppSelectionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

const StyledSelect = styledElement("div")`
  fieldset {
    legend {
      visibility: visible;

      span {
        background: #f4f6f8;
      }
    }
  }
`;

const DashboardSidebar = ({
  apps,
  selectedApp,
  corps,
  selectedCorp,
  role,
  isOpenSidebar,
  onCloseSidebar,
  onChangeApp,
  onChangeCorp,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Logo />
          <span style={{ marginLeft: "15px", fontSize: "18px" }}>
            EzDataStore
          </span>
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <AppSelectionStyle>
          <Box style={{ width: "100%" }}>
            <StyledSelect>
              <Select
                label="App"
                value={selectedApp}
                onChange={(e) => onChangeApp(e.target.value)}
                style={{ width: "100%" }}
              >
                {apps?.map((app, index) => (
                  <MenuItem key={index} value={app.uId}>
                    {app.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledSelect>
          </Box>
        </AppSelectionStyle>
        <AppSelectionStyle style={{ marginTop: 10 }}>
          <Box style={{ width: "100%" }}>
            <StyledSelect>
              <Select
                label="Corporate"
                value={selectedCorp}
                onChange={(e) => onChangeCorp(e.target.value)}
                style={{ width: "100%" }}
              >
                {corps?.map((corp, index) => (
                  <MenuItem key={index} value={corp.uId}>
                    {corp.corpName}
                  </MenuItem>
                ))}
              </Select>
            </StyledSelect>
          </Box>
        </AppSelectionStyle>
      </Box>

      <NavSection
        navConfig={role === "superAdmin" ? adminSidebar : userSidebar}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

DashboardSidebar.propTypes = {
  apps: PropTypes.array,
  selectedApp: PropTypes.string,
  corps: PropTypes.array,
  selectedCorp: PropTypes.string,
  role: PropTypes.string,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onChangeApp: PropTypes.func,
  onChangeCorp: PropTypes.func,
};

DashboardSidebar.defaultProps = {
  apps: [],
  selectedApp: "",
  corps: [],
  selectedCorp: "",
  role: "",
  isOpenSidebar: false,
  onCloseSidebar: () => {},
  onChangeApp: () => {},
  onChangeCorp: () => {},
};

export default DashboardSidebar;
