import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem 
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const AppForm = ({
  initialValues,
  onSubmit,
  apps,
}) => {
  const AppSchema = Yup.object().shape({
    uId: Yup.string().required('App is required'),
    status: Yup.boolean().required('Status is required'),
    // linkAPI: Yup.string().required('Link API is required'),
    // linkCrawlerAPI: Yup.string().required('Link API is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AppSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{minWidth: 200}}>
              <InputLabel>App</InputLabel>
              <Select
                label="App"
                {...getFieldProps('uId')}
                error={Boolean(touched.uId && errors.uId)}
                helpertext={touched.uId && errors.uId}
              >
                {
                  apps.map((app, index) => (
                    <MenuItem key={index} value={app.uId}>{app.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
              
            <FormControl sx={{minWidth: 200}}>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helpertext={touched.status && errors.status}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Not Active</MenuItem>
              </Select>
            </FormControl>

            {/* <TextField
              fullWidth
              label="Link API"
              {...getFieldProps('linkAPI')}
              error={Boolean(touched.linkAPI && errors.linkAPI)}
              helpertext={touched.linkAPI && errors.linkAPI}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              label="Link Crawler API"
              {...getFieldProps('linkCrawlerAPI')}
              error={Boolean(touched.linkCrawlerAPI && errors.linkCrawlerAPI)}
              helpertext={touched.linkCrawlerAPI && errors.linkCrawlerAPI}
              sx={{ width: "50%" }}
            /> */}
          </Stack>
          <LoadingButton
            sx={{m: 2, width: "120px", display: "block"}}
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AppForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.array,
  onSubmit: PropTypes.func,
};

AppForm.defaultProps = {
  initialValues: {
    uId: "",
    status: true,
  },
  apps: [],
  onSubmit: () => {}
};

export default AppForm;
