import axiosReq from "utils/axiosConfig";
import { alertSuccess, alertError } from "./alert.service";

export const getToken = () => localStorage.getItem("accessToken");

export const getAppProducts = async (data) => {
  try {
    const res = await axiosReq.get(`/api/common/products`, {
      params: data,
    });
    return res.data;
  } catch (err) {
    alertError(err.response?.data?.message ?? "");
  }
};

export const getSync = async (data) => {
  try {
    const res = await axiosReq.post("/api/common/products/get", data);
    return res.data;
  } catch (err) {
    alertError(err.response?.data?.message ?? "");
  }
};

export const syncProducts = async (values) => {
  try {
    const res = await axiosReq.post("/api/common/products/sync", values);
    alertSuccess("Success");
    return res.data;
  } catch (err) {
    alertError(err.response?.data?.message ?? "");
  }
};
