import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// service
import { getApp, getApps, updateApp } from "services/app.service";

import AppForm from "./AppForm";

const EditAppForm = () => {
  const history = useHistory();
  const [apps, setApps] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  const { id } = useParams();
  const { isSuccess: isSuccessApps, data: appsData } = useQuery(["registeredApps"], getApps);
  const { isSuccess: isSuccessApp, data: appData } = useQuery(["app", { appId: id }], getApp);

  useEffect(() => {
    if (isSuccessApps) {
      setApps(appsData);
    }
  }, [isSuccessApps, appsData]);

  useEffect(() => {
    if (isSuccessApp) {
      setInitialValues(appData);
    }
  }, [isSuccessApp, appData]);

  const submitApp = useMutation(values => {
    return updateApp({values, appId: id});
  });

  const onSubmitApp = (values) => {
    submitApp.mutate(values, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="App | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit App
          </Typography>
        </Stack>

        <Card>
          {
            initialValues && (
              <AppForm 
                initialValues={initialValues}
                apps={apps}
                onSubmit={onSubmitApp}
              />
            )
          }
          </Card>
      </Container>
    </Page>
  );
}

export default EditAppForm;