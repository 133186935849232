import { useState, useEffect } from 'react';

function getWindowDimensions () {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
}

export const useWindowDimensions = () => {
    const [ windowDimensions, setWindowDimensions ] = useState( getWindowDimensions() );
    useEffect( () => {
        function handleResize () { setWindowDimensions( getWindowDimensions() ); }
        window.addEventListener( 'resize', handleResize );
        return () => window.removeEventListener( 'resize', handleResize );
    }, [] );
    return windowDimensions;
};

export const useModalDimensions = ( initialWidth ) => {
    const { width } = useWindowDimensions();
    const [ modalDimensions, setModalDimensions ] = useState( { modalWidth: initialWidth } );
    useEffect( () => {
        setModalDimensions( { modalWidth: initialWidth } );
    }, [ initialWidth ] );
    useEffect( () => {
        if ( width <= initialWidth ) {
            setModalDimensions( { modalWidth: width - 30 } );
        }
    }, [ width, initialWidth ] );
    return modalDimensions;
};